"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vacancies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const Row_1 = require("../components/Row");
const Shift_1 = require("../components/Shift");
const VacanciesTitle_1 = require("../components/VacanciesTitle");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
// DRAFT is handled separately
// TODO: add BOOKED if compact mode is enabled
const CELL_STATUSES = [
    'ARCHIVED',
    'VACANT',
    'PUBLISHED',
    'TENDERED',
    'APPLIED',
    'SUGGESTED',
    'PRESENTED',
    'ACCEPTED',
    'BOOKED',
    'ABSENT',
];
const toId = (value) => value.id;
const Vacancies = (props) => {
    const { startDate, week, shifts: allShifts, shiftPopover } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onCreateDraftShift, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(props);
    if (!week)
        return null;
    const shifts = (0, get_shifts_in_week_1.getShiftsInWeek)(week, allShifts.filter((0, by_filter_1.byCellFilter)(props)));
    const shiftsToDisplay = props.includeBooked
        ? shifts
        : shifts.filter(({ bookedCandidateId }) => !bookedCandidateId);
    if (!shifts.length && !enableEditing && !props.standardShiftType)
        return null;
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(VacanciesTitle_1.VacanciesTitle, { ...props, startDate: startDate, standardShiftType: props.standardShiftType, shiftIds: shiftsToDisplay.map(toId) }) }), week.days.map((day) => {
                const shiftsOnDay = (0, lodash_1.groupBy)(shiftsToDisplay.filter((shift) => shift.date === day.dateFormatted), 'status');
                const draftShifts = shiftsOnDay['DRAFT'] ?? [];
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: day.disabled, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [CELL_STATUSES.map((status) => {
                                const shifts = shiftsOnDay[status] ?? [];
                                const isArchived = status === 'ARCHIVED';
                                const isUrgent = shifts.every((shift) => shift.isUrgent) && !isArchived;
                                if (!shifts.length)
                                    return null;
                                return ((0, jsx_runtime_1.jsx)(Shift_1.Shift, { ...props, interestedCandidates: shifts[0].interestedCandidates, isInThePast: day.isPast, popoverContent: shiftPopover, shiftIds: shifts.map(toId), status: status, unselectable: isArchived, isUrgent: isUrgent }, status));
                            }), enableEditing && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShifts, { shifts: draftShifts, shiftVariant: props, isPast: day.isPast, onClickNewShift: () => onCreateDraftShift(day.dateFormatted, shifts[0]?.scheduleSlotId), onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateFormatted));
            })] }));
};
exports.Vacancies = Vacancies;
