"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resource = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const Shift_1 = require("../components/Shift");
const ShiftError_1 = require("../components/ShiftError");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const use_schedule_errors_1 = require("../utils/use-schedule-errors");
const Resource = (props) => {
    const { week, shifts: allShifts, availability: allAvailability, shiftPopover, workingTimeDirectiveRuleSet, } = (0, useSchedule_1.useSchedule)();
    const { candidateShifts, candidateAvailability } = (0, react_1.useMemo)(() => {
        const candidateShifts = allShifts.filter((0, by_filter_1.byCellFilter)({
            scheduleResourceId: props.scheduleResourceId,
            bookedCandidateId: props.candidateId,
        }));
        const candidateAvailability = allAvailability.filter(({ candidateId }) => candidateId === props.candidateId);
        return { candidateShifts, candidateAvailability };
    }, [allShifts, props.candidateId, props.scheduleResourceId]);
    const activeCandidateShifts = (0, react_1.useMemo)(() => candidateShifts.filter((shift) => shift.status !== 'CANCELLED'), [candidateShifts]);
    const errors = (0, use_schedule_errors_1.useScheduleErrors)(activeCandidateShifts, workingTimeDirectiveRuleSet);
    if (!week)
        return null;
    const shifts = (0, get_shifts_in_week_1.getShiftsInWeek)(week, candidateShifts);
    const availability = (0, get_shifts_in_week_1.getShiftsInWeek)(week, candidateAvailability);
    if (!shifts.length && !availability.length && !props.permanent)
        return null;
    const bookedShifts = shifts.filter(({ status, bookedCandidateId }) => bookedCandidateId === props.candidateId && status === 'BOOKED');
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { title: props.weekTitle ? 'week' : 'name', week: week.number, name: props.name, avatarUrl: props.avatarUrl, resourceUrl: props.resourceUrl, bookedShifts: bookedShifts }) }), week.days.map((day) => {
                const shiftsOnDay = shifts.filter((shift) => shift.date === day.dateFormatted);
                const isOccupied = shiftsOnDay.some((shift) => ['BOOKED', 'ABSENT', 'SUGGESTED'].includes(shift.status) &&
                    shift.bookedCandidateId);
                const availabilityOnDay = availability.filter(({ date }) => date === day.dateFormatted);
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: day.disabled, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [shiftsOnDay.map((shift) => {
                                const isCancelled = shift.status === 'CANCELLED';
                                return ((0, jsx_runtime_1.jsx)(Shift_1.Shift, { title: props.showUnitName && !isCancelled
                                        ? shift.unitName
                                        : undefined, isInThePast: day.isPast, shiftIds: [shift.id], popoverContent: shiftPopover, ...props, ...shift, error: (0, jsx_runtime_1.jsx)(ShiftError_1.ShiftError, { errors: errors.filter((error) => error.shiftId === shift.id) }) }, shift.id));
                            }), !isOccupied &&
                                availabilityOnDay?.map((availability) => ((0, jsx_runtime_1.jsx)(Shift_1.ShiftAvailability, { ...availability }, availability.id)))] }) }, day.dateFormatted));
            })] }));
};
exports.Resource = Resource;
